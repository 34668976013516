import { ArticleTeaserBodytextTheme } from '@/components/ArticleTeaser/Bodytext/theme';
import { OptionalLink, OptionalLinkProps } from '@/components/OptionalLink';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { ArticleTeaserBodytext } from './ArticleTeaserBodytext';

export interface StandaloneArticleTeaserBodytextProps extends StandaloneComponentProps<typeof ArticleTeaserBodytext> {
  links?: {
    article?: OptionalLinkProps;
    category?: OptionalLinkProps;
  };
  headline?: string;
  category?: string;
  description?: string;
  footer?: string;
  image: string;
  subscriptionLogo?: ReactNode;
  isPaidArticle?: boolean;
}

export const StandaloneArticleTeaserBodytext: StandaloneComponent<StandaloneArticleTeaserBodytextProps> = ({
  links,
  headline,
  category,
  description,
  footer,
  image,
  subscriptionLogo,
  isPaidArticle,
  ...props
}) => {
  return (
    <ArticleTeaserBodytext {...props}>
      {headline && <ArticleTeaserBodytext.Headline>{headline}</ArticleTeaserBodytext.Headline>}

      <ArticleTeaserBodytext.Content>
        <OptionalLink
          {...links?.article}
          content={<ArticleTeaserBodytext.Image src={image} alt={category || ''} options={props?.options?.$image} />}
        />
        <ArticleTeaserBodytext.Group>
          {category && !ArticleTeaserBodytextTheme().category().includes('hidden') && (
            <OptionalLink
              {...links?.category}
              content={<ArticleTeaserBodytext.Category>{category}</ArticleTeaserBodytext.Category>}
            />
          )}
          {description && (
            <OptionalLink
              {...links?.article}
              content={
                <ArticleTeaserBodytext.Description>
                  {isPaidArticle && subscriptionLogo && (
                    <ArticleTeaserBodytext.SubscriptionLogo>{subscriptionLogo}</ArticleTeaserBodytext.SubscriptionLogo>
                  )}
                  {description}
                </ArticleTeaserBodytext.Description>
              }
            />
          )}
          {footer && <ArticleTeaserBodytext.Footer>{footer}</ArticleTeaserBodytext.Footer>}
        </ArticleTeaserBodytext.Group>
      </ArticleTeaserBodytext.Content>
    </ArticleTeaserBodytext>
  );
};
